import {CircularProgress, Grid, Paper, Typography} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import { t } from '../../../utils';
import InstructionButtons from './InstructionButtons';
import ProgressBar from './ProgressBar';
import SubPage from './SubPage';
import firstSubPageImage from '../../../images/tutorial/subPage1Image.png';
import secondSubPageImage from '../../../images/tutorial/subPage2Image.png';
import thirdSubPageImage from '../../../images/tutorial/subPage3Image.png';
import instructionsStyles from './Instructions.styles';
import useEventLogger from '../../../analytics/useEventLogger';
import {SnackbarStateContext} from "../../../store/snackbarState/SnackbarStateProvider";
import ErrorOutLineIcon from '@material-ui/icons/ErrorOutline';
interface InstructionsProps {
  handleDismissInstructions: (lastStep: boolean) => void;
  disabled?: boolean;
}

const Instructions: React.FC<InstructionsProps> = ({ handleDismissInstructions, disabled }) => {
  const { logActions } = useEventLogger();
  const {snackbarState} = useContext(SnackbarStateContext);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>()
  const amountOfSteps = 3;

  const classes = instructionsStyles(isError)();

  useEffect(() => {
    logActions('tutorial_begin', 'Tutorial', 'tutorial_0');
  }, []);

  useEffect(() => {
    const state = snackbarState.snackbars.length > 0;
    setShowInfo(state)
    if(state)
    {
      const msg = snackbarState.snackbars[0];
      setError(msg.isError ?? false)
      setMessage(msg.message);
    }
  }, [snackbarState.snackbars])
  
  const handleProceedToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const currentSubPage = () => {
    switch (currentStep) {
      case 0:
        return (
          <SubPage
            paragraphText={t('instructions.instructionsParagraph1')}
            image={firstSubPageImage}
          />
        );
      case 1:
        return (
          <SubPage
            paragraphText={t('instructions.instructionsParagraph2')}
            image={secondSubPageImage}
          />
        );
      case 2:
        return (
          <SubPage
            paragraphText={t('instructions.instructionsParagraph3')}
            image={thirdSubPageImage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Paper className={classes.root}>
      <Grid container className={classes.instructionsContainer}>
        <Grid item xs={12} className={classes.gridItem}>
          {currentSubPage()}
        </Grid>
        <Grid item xs={12} className={classes.stepIndicatorGridItem}>
          <ProgressBar currentStep={currentStep} amountOfSteps={amountOfSteps} />          
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <InstructionButtons
            {...{ currentStep, handleDismissInstructions, handleProceedToNextStep, disabled }}
          />
          {showInfo && message?.length ? <div className={classes.infoContainer}>
            <div style={{height: '25px', width: '25px'}}>
              {isError ? 
                  <ErrorOutLineIcon style={{color: 'white'}} />
                  : <CircularProgress className={classes.spinner} size={'25px'}/>
              }
            </div>
            <div className={classes.infoText}>
              <Typography variant={'body1'}>{message}</Typography>
            </div>
          </div> : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Instructions;
