import React, {useContext, useEffect, useState} from 'react';
import {Grid, Typography} from "@material-ui/core";
import SelectionButton from "../common/SelectionButton";
import {t} from "../../utils";
import {Layout} from "../../axios/instances/interfaces";
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {DecoStateAction} from "../../store/decoState/interfaces";
import {DialogStateContext} from "../../store/dialogState/DialogStateProvider";
import {DialogStateAction} from "../../store/dialogState/interfaces";
import SelectBaseLayoutDialog, {layoutOption} from "./SelectBaseLayoutDialog";
import LayoutOptionsPageStyles from "./LayoutSelection.styles";
import userDeco from "../../images/menuImages/userDeco.jpg";
import emptyDeco from "../../images/menuImages/emptyDeco.jpg";
import defaultDeco from "../../images/menuImages/defaultDeco.jpg";
import {GlobalStateContext} from "../../store/globalState/GlobalStateProvider";
import useEventLogger from "../../analytics/useEventLogger";


interface LayoutProps {
    onContinueToDecorating: () => void;
    onCreateNewLayout: (layoutId?: number) => void;
}

const LayoutSelection: React.FC<LayoutProps> = ({
    onContinueToDecorating,
    onCreateNewLayout,
    ...rest
    }: LayoutProps)=> {
    
    const { logActions } = useEventLogger();
    
    const [apartmentLayout, setApartmentLayout] = useState<Layout>()
    const [userLayout, setUserLayout] = useState<Layout>()
    const {decoState, dispatch: decoStateDispatch} = useContext(DecoStateContext)
    const {dispatch: dialogDispatch} = useContext(DialogStateContext);
    const {globalState} = useContext(GlobalStateContext);
    
    const classes = LayoutOptionsPageStyles();
    const allowUserDecorating = globalState.tenantSettings.gb4dAllowEndUserDecorating && decoState.decorationOptions?.length;
    const showEmptyLayout = decoState.layoutOptions?.length
    
    useEffect(() => {
        
        // TODO: if dirty layout is found. Prompt user to continue or reset to last saved state. 
        
        const a = decoState.layoutOptions?.find(l => l.apartmentId && !l.userId) ?? decoState.layoutOptions?.find(l => l.groupId);
        const u = decoState.layoutOptions?.find(l => l.userId && !l.isDirty)
        
        setApartmentLayout(a)
        setUserLayout(u)
    },[])
    
    const customDecoButtonContent = () => {
        
        // No user layout exist. Caption to create new one
        if(userLayout === undefined)
        {
            return t('decoration.layout.new')
        }
        
        // User deco has been selected. Caption to edit it
        if(decoState.selectedLayout === userLayout)
        {
            return t('decoration.layout.edit');
        }

        // User deco exists, but is not selected. Caption to select
        return  t('decoration.select')
    }
    
    // 1) no user deco exists = prompt to create a new one 
    // 2) User deco has not been selected = select user layout 
    // 3) user deco has been selected = allow to proceed to editing
    const userDecoAction = () => {
        // No user layout yet, prompt to create a new one
        if(userLayout === undefined){
            
            const options: layoutOption[] = [{
                id: undefined,
                caption: t('decoration.layout.empty'),
                imageUrl: emptyDeco
            }]
            
            if(apartmentLayout) 
            {
                options.push(
                    {
                        id: apartmentLayout?.id,
                        caption: t('decoration.layout.apartment'),
                        imageUrl: defaultDeco
                    })
            }
            if(options.length === 1 && onCreateNewLayout)
            {
                onCreateNewLayout(options[0].id);
                onContinueToDecorating();
            }
            else
            {
                // Open custom dialog to initialize user deco
                dialogDispatch({
                    type: DialogStateAction.CUSTOM_DIALOG,
                    data: <SelectBaseLayoutDialog
                        onSelect={(id) => {
                            onCreateNewLayout(id);
                            onContinueToDecorating();
                        }}
                        options={options}/>
                })
            }
        }
        // Edit user deco. Enabled after user deco has been selected
        else if(decoState.selectedLayout === userLayout) {
            onContinueToDecorating();
        }
        // Select user deco
        else {
            decoStateDispatch({type: DecoStateAction.SELECT_LAYOUT, data: userLayout})
            logActions('layout_selected', 'user', 'layout_selected');
        }
    }
    
    return(
        <div className={classes.page}>
            <>
                <Typography className={classes.contentHeader} variant={"body1"}>{t('decoration.decorate')}</Typography>
                <Typography variant={"body2"}>{t('decoration.info')}</Typography>
                <div className={classes.grid}>
                    <Grid className={classes.gridContainer} container spacing={2} >
                        {allowUserDecorating ? <Grid className={classes.gridItem} item xs={12}>
                            <SelectionButton className={classes.gridContent}
                                             isSelected={decoState.selectedLayout === userLayout}
                                             selectCaption={ customDecoButtonContent() } // Switch caption depending on user layout state
                                             selectedCaption={userLayout ? t('decoration.selected') : undefined} // Dont display if layout doesn't exist
                                             selectionName={userLayout ? t('decoration.layout.user') : undefined} // Dont display if layout doesn't exist
                                             selectionImageUrl={userDeco}
                                             allowReselect={true} // This allows; first select, then edit
                                             onSelect={userDecoAction}
                            />
                        </Grid> : undefined}
                        {apartmentLayout && <Grid className={classes.gridItem} item xs={12}>
                            <SelectionButton
                                className={classes.gridContent}
                                isSelected={decoState.selectedLayout === apartmentLayout}
                                selectCaption={t('decoration.select')}
                                selectedCaption={t('decoration.selected')}
                                selectionName={t('decoration.layout.apartment')}
                                selectionImageUrl={defaultDeco}
                                onSelect={() => {
                                    decoStateDispatch({type: DecoStateAction.SELECT_LAYOUT, data: apartmentLayout})
                                    logActions('layout_selected', 'default', 'layout_selected');
                                }}
                            />
                        </Grid>}
                        {showEmptyLayout ? <Grid className={classes.gridItem} item xs={12}>
                            <SelectionButton className={classes.gridContent}
                                             isSelected={decoState.selectedLayout === undefined}
                                             selectCaption={t('decoration.select')}
                                             selectedCaption={t('decoration.selected')}
                                             selectionName={t('decoration.layout.empty')}
                                             selectionImageUrl={emptyDeco}
                                             onSelect={() => {
                                                 decoStateDispatch({
                                                     type: DecoStateAction.SELECT_LAYOUT,
                                                     data: undefined
                                                 })
                                                 logActions('layout_selected', 'empty', 'layout_selected');
                                             }}
                            />
                        </Grid> : null}
                    </Grid>
                </div>
            </>
        </div>);
}

export default LayoutSelection;