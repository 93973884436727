import React, {useContext, useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import ContentMenuStyles from "./ContentMenu.styles";
import Menu from "@material-ui/icons/Menu"
import ArrowBack from "@material-ui/icons/ArrowBack"
import ArrowForward from "@material-ui/icons/ArrowForward"
import Options from "./Options";
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {t} from "../../utils";
import {GlobalStateContext} from "../../store/globalState/GlobalStateProvider";
import {GlobalStateAction} from "../../store/globalState/interfaces";

interface props {
    createNewLayout: (id?: number) => void;
    selectTheme: (themeId: number) => void;
    saveDecorations?: () => void;
}

const ContentMenu: React.FC<props> = ({saveDecorations, createNewLayout, selectTheme}: props) => {



    const {decoState} = useContext(DecoStateContext);
    const {dispatch, globalState} = useContext(GlobalStateContext);
    
    const [content, setContent] = useState<JSX.Element | undefined>(globalState.activePage);
    const [header, setHeader] = useState<string>()
    const [headerIcon, setHeaderIcon] = useState<JSX.Element>()
    const [isHidden, setHidden] = useState<boolean>(globalState.isPanelHidden);
    const [userSetHidden, setUserSetHidden] = useState(globalState.isPanelHidden);
    const [isInitiated, setInitiated] = useState(globalState.activePage !== undefined || !isHidden); // Define ear content
    
    const classes = ContentMenuStyles({isHidden: isHidden})();

    // Hide panel when manipulating decorations. Also keep hidden, if panel was user hidden.
    useEffect(() => {
        if(isInitiated) {
            const isHidden = decoState.isDecorationSelected || userSetHidden
            setHidden(isHidden);
        }
    }, [decoState.isDecorationSelected])
    
    // Tell others that the panel is now not visible.
    useEffect(() => {
        dispatch({type: GlobalStateAction.HIDE_PANEL, data: isHidden})
    }, [isHidden])
    
    useEffect(() => {        
        setContent(globalState.activePage);        
    }, [globalState.activePage])
    
    useEffect(() => {
        setHeader(globalState.headerText);
        setHeaderIcon(globalState.headerIcon);
    },[globalState.headerText])
    
    const openPage = (page?: JSX.Element) => {
        setContent(page);
        dispatch({type: GlobalStateAction.OPEN_PAGE, data: page})
    }
    
    return(<div className={classes.menuRoot}>
        <div className={classes.panel}>
            <div className={classes.buttonContainer}>
                <div className={classes.earButton} onClick={ () => {
                    setInitiated(true)
                    setUserSetHidden(!isHidden);
                    setHidden(!isHidden);
                }}>
                    { isInitiated ?
                        isHidden ?
                            <ArrowForward/>
                            :
                            <ArrowBack/>
                        :
                        <>
                            <Typography 
                                variant={'body1'}
                                className={classes.startButtonText}>
                                {t('navigation.startHere')}
                            </Typography>
                            <Menu/>
                        </>
                    }
                </div>                
            </div>
            {header && 
            <div className={classes.header}>
                {headerIcon}
                <Typography className={classes.headerText}>{header}</Typography>
            </div>
            }
            {content ?
                content
                :
                <Options OnContentSelected={openPage}
                         saveDecorations={saveDecorations}
                         createNewLayout={createNewLayout}
                         selectTheme={selectTheme}
                />
            }
        </div>

    </div>);
}

export default ContentMenu;