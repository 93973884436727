import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const instructionButtonsStyles = makeStyles((theme: Theme) =>  
  createStyles({
    buttonContainer: {
        [theme.breakpoints.down('xs')]:{
            padding: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`
        },
      width: '100%',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      alignItems: 'space-between',
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        marginTop: 0,
          padding: theme.spacing(1),
      },
    },
    buttonGridItem: {
      width: '100%',
      padding: `0px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    primaryButton: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: theme.spacing(1),
    },
    secondaryButton: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: theme.spacing(1),
    }
  }),
);

export default instructionButtonsStyles