import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import ProductButton from "../common/ProductButton";
import decorationSelectionStyles from "./DecorationSelection.styles";
import {Decoration} from "../../axios/instances/interfaces"
import {DecoStateContext} from "../../store/decoState/DecoStateProvider";
import {DecoStateAction} from "../../store/decoState/interfaces";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import {GlobalStateAction} from "../../store/globalState/interfaces";
import {GlobalStateContext} from "../../store/globalState/GlobalStateProvider";
import NavigationBar, {navigationContent} from "../common/Navigation/NavigationBar";
import {DialogStateAction} from "../../store/dialogState/interfaces";
import SelectBaseLayoutDialog, {layoutOption} from "./SelectBaseLayoutDialog";
import {DialogStateContext} from "../../store/dialogState/DialogStateProvider";
import emptyDeco from "../../images/menuImages/emptyDeco.jpg";
import defaultDeco from "../../images/menuImages/defaultDeco.jpg";

import Save from "@material-ui/icons/Save"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {t} from "../../utils";
import {SettingsStateContext} from "../../store/settingsState/SettingsStateProvider";
import DecorationTutorial from "../Tutorial/Decorating/DecorationTutorial";
import useEventLogger from "../../analytics/useEventLogger";
import {getDateFnsLocale} from "../../i18n";

interface category{
    caption: string,
    content: Decoration[],
    categoryId: number
}

interface props{
    goBack?: () => void;
    saveDecorations?: () => void;
    onCreateNewLayout?: (id?: number) => void;
}

export const DecorationSelection: React.FC<props> = ({
    goBack,
    saveDecorations, 
    onCreateNewLayout
    }) => {

    const {logActions} = useEventLogger();
    
    const classes = decorationSelectionStyles();
    const {decoState, dispatch: decoStateDispatch} = useContext(DecoStateContext)
    const {globalState, dispatch: globalStateDispatch} = useContext(GlobalStateContext);
    const {dispatch: dialogDispatch} = useContext(DialogStateContext);
    const {settingsState} = useContext(SettingsStateContext);
    const cameraState = useRef<number | undefined>();
    const [categories, setCategories] = useState<category[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        // Set decoration mode
        globalStateDispatch({type: GlobalStateAction.SET_CAMERA_MODE, data: 2})
        // Display tutorial if not disabled
        if(!settingsState.ignoreDecorationTutorial && !decoState.tutorialShown)
        {
            decoStateDispatch({type: DecoStateAction.TUTORIAL_STATE, data: true}) // Don't show tutorial twice this session
            dialogDispatch({type: DialogStateAction.CUSTOM_DIALOG, data: <DecorationTutorial/>})
        }
        return() => {
            decoStateDispatch({type: DecoStateAction.SELECT_DECO, data: undefined});
            // If mode is not already "overhead"
            if(cameraState.current !== 0) {
                // Return to first person mode
                globalStateDispatch({type: GlobalStateAction.SET_CAMERA_MODE, data: 1})
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        cameraState.current = globalState.cameraMode;
    }, [globalState.cameraMode])
    
    const loadDecoMessage = (d: Decoration) => {
        logActions('deco_added', 'user added decoration', 'deco_added');
        decoStateDispatch({type: DecoStateAction.LOAD_DECO, data: d});
    }
    
    useMemo(() => {
        
        const decorations = decoState.decorationOptions;
        const categories: category[] = [];
        const lanStr = localStorage.getItem('i18nextLng') as string;
        const code = getDateFnsLocale();
        
        decorations?.reduce((c, a) => {

            const loc = a.localizations[code?.code ?? lanStr];
            const dLoc = a.localizations["en"]; 
            
            if(!loc && !dLoc)
            {
                return c;
            }
            const id = loc?.categoryId ?? dLoc?.categoryId;

            let cat = c.find(x => x.categoryId === id)
            if(cat)
            {
                cat.content.push(a)
            }
            else{
                cat = {
                    caption: loc?.category ?? dLoc.category,
                    categoryId: id,
                    content: [a]
                }
                c.push(cat)
            }
            return c;
        }, categories)
        
        setCategories(categories);
        
    },[decoState.decorationOptions])
    
    const dragContainer = (y: number) => {
        if(!containerRef.current)
        {
            return;
        }
        containerRef.current.scrollTop -= y;
    }
    
    const renderDecorations = (decorations: Decoration[]) => {
        return <>
            {decorations.map((d) =>{
                const lan = localStorage.getItem('i18nextLng') as string;
                const code = getDateFnsLocale();
                const loc = d.localizations[code?.code ?? lan];
                const def = d.localizations['en'];
                return <ProductButton key={d.id} 
                                      className={''} 
                                      productName={loc?.name ?? def.name} 
                                      productImageUrl={d.thumbnailSource} 
                                      productDescription={loc?.description ?? def.description}
                                      //onItemDrag={() => loadDecoMessage(d)}
                                      onClicked={() => loadDecoMessage(d)}
                                      onManualDrag={dragContainer}
                />
            })}
        </>
    }

    // TODO: this is duplicate from layout panel, combine
    const onResetDecoration = () => {
        const apartmentLayout = 
            decoState.layoutOptions.find(l => l.apartmentId != null && l.userId == null) 
            ?? decoState.layoutOptions.find(l => l.groupId != null)

        const options: layoutOption[] = [{
            id: undefined,
            caption: t('decoration.layout.empty'),
            imageUrl: emptyDeco
        }]

        if(apartmentLayout)
        {
            options.push(
                {
                    id: apartmentLayout?.id,
                    caption: t('decoration.layout.apartment'),
                    imageUrl: defaultDeco
                })
        }
        
        if(options.length === 1 && onCreateNewLayout)
        {
            onCreateNewLayout(options[0].id);
        }
        else
        {
            // Open custom dialog to initialize user deco
            dialogDispatch({
                type: DialogStateAction.CUSTOM_DIALOG,
                data: <SelectBaseLayoutDialog
                    onSelect={(id) => {
                        if(onCreateNewLayout)
                        {
                            onCreateNewLayout(id);
                        }
                    }}
                    options={options}/>
            })
        }
    }
    
    const onSaveDecoration = () => {
        if(saveDecorations) {
            saveDecorations();
            logActions('save_decoration', 'save_decoration', 'save_decoration')
        }
    }
    
    const nav = () => {
        const navContent: navigationContent[] = [];
        
        if(goBack) {
            navContent.push({
                action: goBack,
                caption: t('navigation.back'),
                icon: <ArrowBackIos/>
            })
        }
        
        if(saveDecorations && globalState.token)
        {
            navContent.push({
                action: onSaveDecoration,
                caption: t('decoration.layout.save'),
                icon: <Save />
            })
        }
        
        if(onCreateNewLayout)
        {
            navContent.push({
                action: onResetDecoration,
                caption: t('decoration.layout.reset')
            })
        }
        
        return navContent;
    }    
    
    return(
        <>
            {goBack && <NavigationBar onReturn={goBack} customContent={nav()} /> }
            <div className={classes.pageContent} ref={containerRef} >
                    {decoState.decorationOptions && categories?.map((c, i) => {
                        return <Accordion className={classes.accordion} key={i} TransitionProps={{unmountOnExit: true}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography className={classes.accordionHeading}>{c.caption}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionContent}>
                                {renderDecorations(c.content)}
                            </AccordionDetails>
                        </Accordion>
                    })}              
            </div>
        </>)
}