import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const poweredByLabelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '15px',
      position: 'absolute',
      left: theme.spacing(2),
      bottom: theme.spacing(2),
      zIndex: 10,
      minWidth: 200,
        maxWidth: 240,
      padding: 0,
        [theme.breakpoints.down('xs')]:{
            display: 'none'
        },
        '@media screen and (max-height: 600px) and (orientation: landscape)':{
          display: 'none'
        }
    },
    media: {
      height: 30,
      width: 160,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1)
    },
    poweredByContent: {
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(1)
    },
    poweredBy: {
      marginRight: theme.spacing(1)
    },
    modelIsIndicativeContent: {
      padding: theme.spacing(0),
      '&:last-child': {
        paddingBottom: theme.spacing(1)
      }
    },
    modelIsIndicative: {
      filter: 'opacity(0.7)',
      margin: theme.spacing(0),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    versionContent:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        padding: '0'
    },
      versionIdentifier:{
        marginLeft: '-2px',
          paddingRight: theme.spacing(1)
      }
  }),
);

export default poweredByLabelStyles