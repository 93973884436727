import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const instructionsStyles = (errorMessage: boolean) => makeStyles((theme: Theme) =>  
  createStyles({
    root: {
      width: '30%',
      maxWidth: '810px',
      minWidth: '400px',
      borderRadius: theme.spacing(2),
      borderStyle: 'none',
      boxShadow: 'none',
      zIndex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        width: '100%',
        height: '100%'
      },
        '@media screen and (max-width: 600px) and (orientation: portrait)':{
            width: '100%',
            height: '100%',
            borderRadius: '0'
        }
    },
    instructionsContainer: {
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        height: '100%',
        marginTop: 0,
        padding: 0
      },
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            height: '100vh',
            alignContent: 'flex-start',
        }
    },
    stepIndicatorGridItem: {
      padding: 0,
      alignContent: 'center',
      textAlign: 'center',
        margin: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px`,
      '@media screen and (max-height: 600px) and (orientation: landscape)': {
        height: '10px',
      },
    },
    gridItem: {
      marginTop: theme.spacing(3),
      alignContent: 'center',
      textAlign: 'center',
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        marginTop: 0,
        padding: 0
      }
    },
    buttonContainer: {
        [theme.breakpoints.down('xs')]:{
            padding: 0
        },
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        alignSelf: 'flex-end'
      },
        alignContent: 'center',
        textAlign: 'center',
    },
      infoContainer: {
        display: 'none',
        flexDirection: 'row',
          justifyContent: 'left',
          alignItems: 'center',
          margin: 0,
          padding: theme.spacing(2),
          backgroundColor: errorMessage ? '#f44336' : undefined,
          borderBottomRightRadius: theme.spacing(2),
          borderBottomLeftRadius: theme.spacing(2),
          '@media screen and (max-width: 900px) and (orientation: portrait)': {
            display: 'flex',
        },
          '@media screen and (max-height: 900px) and (orientation: landscape)': {
              display: 'flex',
          },
          '@media screen and (max-width: 600px)': {
            borderRadius: '0'
          }
      },
      infoText:{
        color: errorMessage ? 'white' : undefined,
        width: '-webkit-fill-available'
      },
      spinner: {
        alignSelf: 'center',
        justifySelf: 'center',
        textAlign: 'center',
        justifyItems: 'center',
        //marginLeft: 'calc(50% - 12px)',          
        marginTop: theme.spacing(0.5)
      },
      
  }),
);

export default instructionsStyles