import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const subPageStyles = makeStyles((theme: Theme) =>  
  createStyles({
    root: {
      borderRadius: theme.spacing(2),
      borderStyle: 'none',
      boxShadow: 'none',
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    media: {
      minHeight: 250,
      height: '15vh',
      backgroundSize: 'cover',
      borderRadius: theme.spacing(2),
      '@media screen and (max-height: 600px) and (orientation: landscape)': {
        minHeight: '40vh'
      },
    },
    cardContentRoot: {
        paddingTop: theme.spacing(1),
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        padding: 0,
        paddingBottom: 0,
        marginBottom: 0,
        '&:last-child': {
          paddingBottom: 0
        },
      },
    }
  }),
);

export default subPageStyles