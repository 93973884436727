import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface controlProps{
    posX: number,
    posY: number
}
const useDecorationControlStyles = ({posX, posY}: controlProps) => 
    makeStyles((theme: Theme) => 
        createStyles({
            floatingContainer: {
                display: "flex",
                position: "absolute",
                left: `calc(${posX}px - 100px)`,
                bottom: `calc(${posY}px - 30px)`,
                width: '200px',
                zIndex: 150,
                overflow: 'hidden',
                WebkitTouchCallout: 'none',
                userSelect: 'none',
            },
            centralButton: {
                width: '100% !important',
                minWidth: '0px',
                padding: '0',
                aspectRatio: '1/1',
                borderRadius: '100%',
                background: theme.palette.primary.main,
                color: 'white',
                fill: 'white',
                touchAction: 'none',
                WebkitTouchCallout: 'none',
                userSelect: 'none',
                '&:hover': {
                    background: 'white',
                    fill: theme.palette.primary.main,
                    color: theme.palette.primary.main
                }
            },
            sideButton: {
                width: '100% !important',
                minWidth: '0px',
                margin: '0',
                padding: '0',
                aspectRatio: '1/1',
                borderRadius: '100%',
                background: 'white',//theme.palette.secondary.main
                touchAction: 'none',
                WebkitTouchCallout: 'none',
                userSelect: 'none',
                '&:hover': {
                    background: theme.palette.primary.main,
                    color: 'white'
                }
            },
            gridItem: {
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center',
                WebkitTouchCallout: 'none',
                userSelect: 'none',
            },
            buttonIcon: {
                touchAction: 'none',
                width: '100% !important',
                height: '100% !important',
                padding: '10% !important',
                WebkitTouchCallout: 'none',
                userSelect: 'none',
            },
            centralIcon: {
                width: '90% !important'
            }
        })
)
export default useDecorationControlStyles;