import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'


const snackBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '15px',
      border: 'none',
      width: 'auto', 
      alignItems: 'center',
      "& .MuiAlert-icon": {
        alignSelf: 'center',
        fontSize: '32px'
      },
        '@media screen and (max-height: 900px) and (orientation: landscape)':{
          display: 'none'
      },
        '@media screen and (max-width: 900px) and (orientation: portrait)':{
            display: 'none'
        }
    },
    spinner: {
      textAlign: 'center',
      marginLeft: 'calc(50% - 12px)',
      marginTop: theme.spacing(0.5)
    },
    hide:{
        display: 'flex',
        flex: '1 0 auto',
    },
      hideButton:{
        color: theme.palette.primary.main,
        alignItems: 'start',
        alignContent: 'start',
        alignSelf: "start",
        textAlign: "start",
        cursor: 'pointer',
      }
  }),
);

export default snackBarStyles