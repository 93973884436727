import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const welcomeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '35vw',
      minWidth: '400px',
      maxWidth: '610px',
      borderRadius: theme.spacing(2),
      borderStyle: 'none',
      boxShadow: 'none',
      
      [theme.breakpoints.down('xs')]: {
        top: '0',
        left: '0',
        position: 'absolute',
        height: '100%',
        width: '100%',
        minWidth: '100%',
        minHeight: '100%',
        // To iOS & Safari
        '@media not all and (min-resolution:.001dpcm)': {
          height: 'auto',
          minWidth: '100%',
        },
        zIndex: 5,
        borderRadius: 0
      },
      '@media screen and (max-height: 600px) and (orientation: landscape)': { 
        width: '100%',
        maxWidth: '100%',
        zIndex: 10,
        height: '100%',
        textAlign: '-webkit-center'
      },
    },
    gridContainer: {
      padding: theme.spacing(4),
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
        height: '100%',
        // To iOS & Safari
        '@media not all and (min-resolution:.001dpcm)': {
          height: 'auto',
          padding: 0
        },
        '@media not all and (min-resolution:.001dpcm) and (orientation: landscape)': {
          //height: 'calc(var(--vh, 1vh) * 90)'
        },
        borderRadius: 0,
        gridGap: '0px',
        //marginTop: '25%'
      },
      '@media screen and (max-height: 600px) and (orientation: landscape)': {
        width: '90%',
        maxWidth: '100%',
        height: '100%',
        alignContent: 'center'
      },
    },
    gridItem: {
      alignContent: 'center',
      textAlign: 'center',
      margin: theme.spacing(2),
      width: '-webkit-fill-available',
      '@media screen and (max-height: 600px) and (orientation: landscape)': {
        margin: 0,
        marginTop: theme.spacing(0.5)
      },
      [theme.breakpoints.down('xs')]:{
        margin: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
      },
      // To iOS & Safari
      '@media not all and (min-resolution:.001dpcm)': {
        padding: 0
      },
    },
    buttonGridItem: {
      margin: theme.spacing(1),      
    },
    descriptionTextGridItem: {
      marginTop: theme.spacing(0),
      alignContent: 'center',
      textAlign: 'center',
      margin: theme.spacing(2),
      width: '-webkit-fill-available',
      '@media screen and (max-height: 600px) and (orientation: landscape)': {
        margin: 0,
        marginTop: theme.spacing(0.5)
      },
      // To iOS & Safari
      '@media not all and (min-resolution:.001dpcm)': {
        padding: 0
      },
    },
    descriptionText: {
      marginBottom: theme.spacing(1),
      fontSize: '0.8rem',
      opacity: 0.6,
    },
    primaryButton: {
      boxShadow: 'none',
      borderRadius: theme.spacing(1),
      width: '100%',
      '& .MuiButton-label ':{
        margin: theme.spacing(0.5)
      }
    },
    poweredBy:{
      display: 'none',
      [theme.breakpoints.down('xs')]:{
        display: 'block',
        minWidth: '100px',
        paddingLeft: theme.spacing(2)
      }
    }
  }),
);

export default welcomeStyles